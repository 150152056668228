import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import Image from './image'

class ItemImage extends React.Component {
  render () {
    const item = this.props.item

    if (item.overviewImg && !item.overviewImgGif) {
      if (item.overviewImgSize) {
        return (
          <Image imgsrc={item.overviewImg}/>
        )
      } else if (item.overviewImg.childImageSharp) {
        const featuredImgFluid = item.overviewImg.childImageSharp.fluid
        return (
          <Img fluid={featuredImgFluid} />
        )
      }
    } else if (item.overviewImg) {
      return (<img alt={`Animation of ${item.title}`} className="item-image--gif" src={`/projects/${item.id}/${item.overviewImg}`} />)
    }

    return (
      <div>
        <p>Searching Image</p>
      </div>
    )
  }
}

ItemImage.propTypes = {
  item: PropTypes.object.isRequired
}

export default ItemImage
