import React from 'react'

import BlogItem from './BlogItem'
import './list.scss'

class Bloglist extends React.Component {
  render () {
    const posts = this.props.posts.map((item) =>
      <BlogItem item={item} key={`bloglist-item-${item.id}`}/>
    )
    const size = this.props.size || 'normal'

    return (
      <div className={`blog-list blog-list--${size}`}>
        {posts}
      </div>
    )
  }
}

export default Bloglist
