import React from 'react'
import { Link } from 'gatsby'

import ItemImage from '../itemImage'

class BlogItem extends React.Component {
  render () {
    const item = this.props.item
    let title = item.title || 'Title missing?'
    const slug = item.overviewURL || item.slug
    const date = item.date || 'once'
    let linkElement

    if (slug && slug.includes('http')) {
      title = (<a href={slug} target="_blank">{title}</a>)
      linkElement = (<a className="blog-list__link" href={slug} target="_blank">&gt; {item.overviewURLTextLong || 'Read'}</a>)
    } else if (slug) {
      title = (<Link to={slug}>{title}</Link>)
      linkElement = (<Link className="blog-list__link" to={slug}>&gt; {item.overviewURLTextLong || 'Read'}</Link>)
    }

    return (
      <article className="blog-list__item">
        <div className="blog-list__item-image">
          <ItemImage item={item}/>
        </div>
        <div className="blog-list__item-text">
          <header>
            <h3>{title}</h3>
            <small>{(item.subType || item.mainType || 'blog').toUpperCase()} - {date}</small>
          </header>
          <section>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.description || item.excerpt
                }}
              /><span> </span>
              {linkElement}
            </p>
          </section>
        </div>
      </article>
    )
  }
}

export default BlogItem
