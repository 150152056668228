import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout/layout-single'
import ProcessBlogs from '../components/datacontent'
import Bloglist from '../components/Bloglist/list'
import Header from '../components/Header/header'
import SEO from '../components/SEO/seo'

class BlogIndex extends React.Component {
  render () {
    const { data } = this.props
    const posts = ProcessBlogs(data.allMdx.edges, data.projectcases.edges, [], true)

    let postsElementsFirst = false
    let postsElementsRest = false
    if (posts.length > 2) {
      postsElementsFirst = posts.slice(0, 2)
      postsElementsRest = posts.slice(2)
    } else {
      postsElementsFirst = posts
    }

    return (
      <Layout>
        <>
          <SEO title="You-et writes" imagefile="blog" location={this.props.location} />
          <Header
            location={this.props.location}
            fluid={data.file.childImageSharp.fluid}
            alt="You-et writes"
            title="You-et writes"
          />
        </>
        <>
          <h1>You-et writes</h1>
          <p>You-et switched sides from machine to human. She writes about her explorations, creations and curiosities. This overview collects blogs, cases and interactive pages from the website.</p>
          <ul className="paragraph-buttons">
            <li><Link to="/project">Design cases</Link></li>
            <li><Link to="/design-process">The design process</Link></li>
            <li><a className="paragraph-button__button-active">Blog</a></li>
          </ul>
          <Bloglist posts={postsElementsFirst} size="big" />
          <Bloglist posts={postsElementsRest} size="normal" />
          <ul className="paragraph-buttons">
            <li><Link to="/project">Design cases</Link></li>
            <li><Link to="/design-process">The design process</Link></li>
            <li><a className="paragraph-button__button-active">Blog</a></li>
          </ul>
        </>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    projectcases: allProjectsJson(
      sort: { fields: [dateOrder], order: DESC },
      filter: {mainType: {in: ["case", "interactive"]}, overviewURL: {regex: "/^(?!http).+/"}},
    ) {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
    file(relativePath: { eq: "you-et.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
